import React, { useState, useEffect, useRef, useContext } from 'react'
import Head from 'next/head'
import classNames from 'classnames'

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Gtm from 'components/scripts/Gtm'
import { ElementorScripts } from 'components/ElementorScripts/ElementorScripts'

import style from './WordPressLandingPage.module.scss'

import { WebpageStructuredData } from 'utils/StructuredData'

import type { WordPressLandingPageProps } from 'types/externalData'

const WordPressLandingPage: React.FC<WordPressLandingPageProps> = ({
  pageData,
  pageTitle,
  seoData,
  slug,
  footerData,
  globalStyling,
  postCss,
  customPath,
}) => {
  const [scripts, setScripts] = useState<
    {
      src: string
      async: boolean
    }[]
  >([])
  const transparent = pageData?.acf?.transparent_nav?.length ? true : false

  const contentRef = useRef<HTMLDivElement>(null)

  // Can't scroll to anchor until document is ready
  useEffect(() => {
    if (pageData.content.rendered && window.location.hash) {
      const anchor = document.getElementById(window.location.hash.substring(1))

      if (anchor) {
        window.scrollTo({
          top: anchor.getBoundingClientRect().top + window.pageYOffset,
          behavior: 'smooth',
        })
      }
    }

    if (pageData.content.rendered) {
      // look for any scripts coming from Wordpress with a
      // `execute-script` class - these will be added to the <head>
      const scriptElements = document.querySelectorAll<HTMLScriptElement>(
        'script.execute-script',
      )
      if (scriptElements && scriptElements.length) {
        setScripts(
          Array.from(scriptElements).map((el) => ({
            src: el.src,
            async: el.async,
          })),
        )
      }
    }
  }, [pageData.content.rendered])

  const buildCanonicalLink = (seoData: any) => {
    const origin = process.env.NEXT_PUBLIC_ORIGIN
    const yoastUrl = seoData?.['yoast_wpseo_canonical'] ?? ''
    const parts = yoastUrl.split('/')
    const protocol = parts[0]
    const host = parts[2]
    const yoastOrigin = protocol + '//' + host

    if (origin === yoastOrigin) {
      return yoastUrl
    }

    return customPath ? `${origin}/${customPath}/${slug}` : `${origin}/${slug}`
  }

  return (
    <>
      <Head>
        <ElementorScripts />
        <script defer src="/assets/scripts/slider.min.js" />
        <script defer src="/assets/scripts/sliderPro.min.js" />
        <script defer src="/assets/scripts/slick.min.js" />
        <style>{globalStyling}</style>
        <style>{postCss}</style>
        <script
          dangerouslySetInnerHTML={{
            __html: `/* <![CDATA[ */
      var cp_calculatedfieldsf_fbuilder_config_1 = {
        obj:
          '{"pub":true,"identifier":"_1","messages":{"required":"This field is required.","email":"Please enter a valid email address.","datemmddyyyy":"Please enter a valid date with this format(mm/dd/yyyy)","dateddmmyyyy":"Please enter a valid date with this format(dd/mm/yyyy)","number":"Please enter a valid number.","digits":"Please enter only digits.","max":"Please enter a value less than or equal to {0}.","min":"Please enter a value greater than or equal to {0}.","previous":"Previous","next":"Next","pageof":"Page {0} of {0}","minlength":"Please enter at least {0} characters.","maxlength":"Please enter no more than {0} characters.","equalTo":"Please enter the same value again.","accept":"Please enter a value with a valid extension.","upload_size":"The file youve chosen is too big, maximum is {0} kB.","phone":"Invalid phone number.","currency":"Please enter a valid currency value."}}',
      };
      /* ]]> */`,
          }}
          type="text/javascript"
        />
        {/* Page Metadata */}
        {seoData?.title ? (
          <title>{seoData.title}</title>
        ) : (
          pageTitle && <title>{`${pageTitle} | Evolve Vacation Rental`}</title>
        )}
        {seoData?.description && (
          <meta content={seoData.description} name="description"></meta>
        )}
        <link href={buildCanonicalLink(seoData)} rel="canonical" />
        <meta
          content={
            seoData?.og_title
              ? seoData.og_title
              : `${pageTitle} | Evolve Vacation Rental`
          }
          property="og:title"
        />
        <meta
          content={
            seoData?.og_description
              ? seoData.og_description
              : seoData?.description
          }
          property="og:description"
        />
        {seoData?.og_image && (
          <meta content={seoData?.og_image} property="og:image" />
        )}
        <meta content={buildCanonicalLink(seoData)} property="og:url" />
        <meta
          content={seoData?.og_type ? seoData.og_type : 'article'}
          property="og:type"
        />
        <meta content={'Evolve Vacation Rental'} property="og:site_name" />
        <meta
          content={
            seoData?.twitter_title
              ? seoData.twitter_title
              : `${pageTitle} | Evolve Vacation Rental`
          }
          name="twitter:title"
        />
        <meta
          content={
            seoData?.twitter_description
              ? seoData.twitter_description
              : seoData?.description
          }
          name="twitter:description"
        />
        {seoData?.twitter_image && (
          <meta content={seoData?.twitter_image} name="twitter:image" />
        )}
        <meta
          content={
            seoData?.twitter_card ? seoData.twitter_card : 'summary_large_image'
          }
          name="twitter:card"
        />
        <meta
          content={seoData?.twitter_site ? seoData.twitter_site : '@EvolveVR'}
          name="twitter:site"
        />
        {scripts && scripts.length
          ? scripts.map((script, i) => (
              <script async={script.async} key={i} src={script.src} />
            ))
          : null}
      </Head>
      <Gtm pageType="marketing page" />
      <Header transparent={transparent} />

      <div
        className={classNames(
          'customElementor',
          style.customPage,
          style.customElementor,
          {
            [style.transparentHeader]: transparent,
          },
        )}
        dangerouslySetInnerHTML={{
          __html: pageData && pageData.content.rendered,
        }}
        ref={contentRef}
      />
      <Footer footerData={footerData} />

      {seoData && (
        <WebpageStructuredData
          canonicalUrl={buildCanonicalLink(seoData)}
          yoastData={seoData}
        />
      )}
      {slug === 'careers' && (
        <script
          async
          defer
          src="https://boards.greenhouse.io/embed/job_board/js?for=evolvevacationrental"
        />
      )}
    </>
  )
}

export default WordPressLandingPage
