/*
  This file is for all non-nested wordpress landing pages (slug only).
  https://evolve.com/careers
*/
import React, { useState, useEffect, useRef, useContext } from 'react'
import type {
  GetStaticPaths,
  GetStaticProps,
  NextPage,
  InferGetStaticPropsType,
} from 'next'
import sass from 'sass'

import { PageHistoryContext } from 'context/PageHistoryContext'

import WordPressLandingPage from 'components/WordPressLandingPage/WordPressLandingPage'

import { filteredSlugs } from 'config/landingPages'

import { parseYoastData } from 'utils/Seo'
import renderSpecialCharacters from 'utils/strings/renderSpecialCharacters'
import {
  fetchWordpressPageData,
  fetchFooterData,
  fetchGlobalOptionsData,
  getLandingPageCss,
} from 'utils/staticData'

import { WordPressLandingPageProps } from 'types/externalData'

export const getStaticPaths: GetStaticPaths<any> = async () => {
  const pageSlugs: {
    path: string
    slug: string
    parent_pillar: string | null
    parent_slug: string | null
  }[] = await (
    await fetch(
      `${process.env.NEXT_PUBLIC_BASE_WP_API_URL}/wp-json/evolve/page-slugs?nested=0&skip_cache=1`,
    )
  ).json()

  // Page slugs are uses as paths to statically generate pages
  const paths = pageSlugs
    .filter((page) => !filteredSlugs.includes(page.slug))
    .map((page) => {
      const slug = page.path

      return {
        params: {
          customPath: slug,
        },
      }
    })

  return {
    paths: paths,
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<WordPressLandingPageProps> = async (
  context,
) => {
  const slug = context?.params?.customPath ?? ''

  const pageData = await fetchWordpressPageData(slug as string, context.preview)

  if (!pageData || (Array.isArray(pageData) && !pageData.length)) {
    return {
      notFound: true,
    }
  }

  const parentPillar = pageData.acf?.parent_pillar ?? null
  const parentPage = pageData.parent ?? null

  // 404 if page has a custom path selected in wordpress
  if (parentPillar || parentPage) {
    return {
      notFound: true,
    }
  }

  const footerData = await fetchFooterData()
  const globalOptionsData = await fetchGlobalOptionsData()
  const postCss = await getLandingPageCss(pageData.id, sass)

  return {
    props: {
      pageData: pageData,
      pageTitle: renderSpecialCharacters(pageData.title.rendered),
      seoData: parseYoastData(pageData.yoast_meta, pageData.head_tags),
      slug: slug,
      footerData: {
        menus: footerData,
        options: globalOptionsData.acf.global_footer_group,
      },
      globalStyling: globalOptionsData.acf.css,
      postCss: postCss,
      lastModifiedDate: new Date().toString(),
    },
    revalidate: process.env.DEFAULT_REVALIDATION_PERIOD
      ? parseInt(process.env.DEFAULT_REVALIDATION_PERIOD)
      : undefined,
  }
}

const SlugLandingPage: NextPage<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({
  pageData,
  pageTitle,
  seoData,
  slug,
  footerData,
  globalStyling,
  postCss,
}) => {
  const [scripts, setScripts] = useState<
    {
      src: string
      async: boolean
    }[]
  >([])

  const contentRef = useRef<HTMLDivElement>(null)

  const { pageHistory } = useContext(PageHistoryContext)

  // Can't scroll to anchor until document is ready
  useEffect(() => {
    if (pageData.content.rendered && window.location.hash) {
      const anchor = document.getElementById(window.location.hash.substring(1))

      if (anchor) {
        window.scrollTo({
          top: anchor.getBoundingClientRect().top + window.pageYOffset,
          behavior: 'smooth',
        })
      }
    }

    if (pageData.content.rendered) {
      // look for any scripts coming from Wordpress with a
      // `execute-script` class - these will be added to the <head>
      const scriptElements = document.querySelectorAll<HTMLScriptElement>(
        'script.execute-script',
      )
      if (scriptElements && scriptElements.length) {
        setScripts(
          Array.from(scriptElements).map((el) => ({
            src: el.src,
            async: el.async,
          })),
        )
      }

      const links = contentRef.current?.querySelectorAll('a')
      links?.forEach((link) => {
        if (link.hasAttribute('href')) {
          link.setAttribute(
            'href',
            link.getAttribute('href') + window.location.search,
          )
        }
      })
    }
  }, [pageData.content.rendered])

  useEffect(() => {
    // first check if history has updated, then if on first page allow default referrer, otherwise pass previous page to referrer
    if (
      pageHistory[pageHistory.length - 1]?.split('?')[0] ===
      window.location.href.split('?')[0]
    ) {
      if (pageHistory.length === 1) {
        window.analytics.page(pageTitle + ' Page')
      } else {
        window.analytics.page(pageTitle + ' Page', {
          referrer: pageHistory[pageHistory.length - 2],
        })
      }
    }
  }, [pageTitle, pageHistory])

  return (
    <WordPressLandingPage
      footerData={footerData}
      globalStyling={globalStyling}
      pageData={pageData}
      pageTitle={pageTitle}
      postCss={postCss}
      seoData={seoData}
      slug={slug}
    />
  )
}

export default SlugLandingPage
